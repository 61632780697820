import '../../css/Register.css';
import { motion } from 'framer-motion';
import { Glow, GlowCapture } from '@codaworks/react-glow';
import { useEffect } from 'react';

function Register() {
    useEffect(() => {
        document.title = "Register"
        document.body.classList.add('Register');
        
        return () => {
            document.body.classList.remove('my-body-class');
        };
    }, []);

    return (
        <div className="Register">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                <GlowCapture>
                    <Glow color="rgb(212, 0, 212)">
                        <div className='main-rectangle-fix-position'>
                            <div className='main-rectangle glowable-element'>
                                <button class="glowable-element" id="rectangle" disabled></button>
                                <p className='glowable-element' id='title'>Register</p>
                                <p className='glowable-element' id='email'>Email</p>
                                <motion.div
                                    whileHover={{ scale: [0.8, 1.0] }}
                                    initial={{ scale: 1.0 }}
                                >
                                    <input type="email" id="email-input" name="email"/>
                                </motion.div>
                                
                                <p id='password'>Password</p>
                                <motion.div
                                    whileHover={{ scale: [0.8, 1.0] }}
                                    initial={{ scale: 1.0 }}
                                >
                                    <input type="text" id="password-input" name="password"/>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: [0.8, 1.0] }}
                                    initial={{ scale: 1.0 }}
                                >
                                    <button id='custom-button'>Register</button>
                                </motion.div>
                            </div>
                        </div>
                    </Glow>
                </GlowCapture>
            </motion.div>
        </div>
    );
}

export default Register;