import { motion } from 'framer-motion';
import '../../css/NotFound.css';
import { useEffect } from 'react';
import kuro from '../../assets/image/404.png';

function NotFound() {
    useEffect(() => {
        document.title = "NotFound"
    }, []);

    return (
        <div className="Main">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                <a href="/home">
                    <p>404 Not Found</p>
                    <img src={kuro} alt="404"/>
                </a>
            </motion.div>
        </div>
    );
}

export default NotFound;