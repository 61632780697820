import '../../css/discord/HowToUse.css'
import { getHeader } from '../../../../utils/app/AppUtils';
import { useEffect } from 'react';
import example from '../../../../../assets/image/example.png'
import { motion } from 'framer-motion';

function HowToUse() {
    useEffect(() => {
        document.body.classList.add('HowToUse');
        document.title = "How to use"
        
        return () => {
            document.body.classList.remove('my-body-class');
        };
    }, []);

    return (
        <div className="HowToUse">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}

                <div className='container'>
                    <div className='title'>
                        <p>Random String</p>
                    </div>

                    <p>Example: </p>
                    <textarea className='code-area' 
                        value="daaa{randomstring10}"
                        readOnly
                    />

                    <p>"10" is length of random strings</p>

                    <div>
                        <img src={example} alt="example" className='image'/>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default HowToUse;