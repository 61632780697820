import bluesky from '../../assets/image/bluesky.png';
import github from '../../assets/image/github.png';
import youtube from '../../assets/image/youtube.png';
import '../../css/Info.css';
import { motion } from 'framer-motion';
import { Glow, GlowCapture } from '@codaworks/react-glow';
import { getHeader } from '../utils/app/AppUtils';
import { useEffect } from 'react';

function getIconHover(src, url, title) {
    return(
        <a href={url}>
            <motion.div
                whileHover={{
                    scale: [0.9, 1.0]
                }}
                initial={{ scale: 1.0 }}
            >
                <img src={src} alt={title}/>
                <p>{title}</p>
            </motion.div>
        </a>
    )
}

function Link() {
    useEffect(() => {
        document.title = "Links"
    }, []);

    return (
        <div className="App">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}

                <GlowCapture>
                    <Glow color='#3790e2;'>
                        <div className='icons-container glowable-element'>
                            {getIconHover(bluesky, "https://bsky.app/profile/3f4.bsky.social", "Bluesky")}
                        </div>

                        <div className='icons-container glowable-element'>
                            {getIconHover(github, "https://github.com/kometarou1145", "Github")}
                        </div>

                        <div className='icons-container glowable-element'>
                            {getIconHover(youtube, "https://www.youtube.com/channel/UCBku6qR3kgIbJRY3fyyUSlg", "YouTube")}
                        </div>
                    </Glow>
                </GlowCapture>
            </motion.div>
        </div>
    );
}

export default Link;