import '../../css/Temu.css';
import temu1 from '../../assets/image/temu-roulette-1.png'
import temu2 from '../../assets/image/temu-roulette-2.png'
import chinese from '../../assets/image/chinese.png'
import { motion } from 'framer-motion';
import { useEffect } from 'react';

function Temu() {
    useEffect(() => {
        document.body.classList.add('Temu');
        
        return () => {
            document.body.classList.remove('my-body-class');
        };
    }, []);

    return (
        <div className="Temu">
            <a href="/home">
                <motion.div 
                    animate={{ rotate: [0, 360] }}
                    transition={{ repeat: Infinity }}
                >
                    <img src={temu2} alt="temu-roulette-2" className="temu-roulette-2"></img>
                </motion.div>

                <img src={temu1} alt="temu-roulette-1" className="temu-roulette-1"></img>

                <motion.div 
                    animate={{ scale: [0.1, 0.5]}}
                    transition={{ repeat: Infinity, repeatDelay: 5, duration: 2}}
                >
                    <img src={chinese} alt="chinese" className="chinese"></img>
                    <p style={{fontSize: 'calc(50px + 2vmin)', color: "green"}}>+100 <span style={{color: "red"}}> Social Credits</span>!!!!11!!1</p>
                </motion.div>
            </a>
        </div>
    );
}

export default Temu;