import logo from '../../../assets/image/logo.svg';
import { motion } from 'framer-motion';

const title = "こめたろうのサイト";

export function getHeader() {
    return(
        <header className="App-header">
            <motion.div 
                animate={{ rotate: 360, scale: 1.0}} 
                transition={{ repeat: Infinity, repeatDelay: 2 }}
                initial={{ scale: 0.5 }}
            >
                <a href="/home">
                    <img src={logo} className="App-logo" alt="logo" />
                </a>
            </motion.div>
            
            <a href="/home">
                <p className="App-title">{title}</p>
            </a>
        </header>
    );
}