import '../../css/discord/Webhook.css'
import { getHeader } from '../../../../utils/app/AppUtils';
import { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';

function Webhook() {
    useEffect(() => {
        document.title = "Webhook"
    }, []);

    const [inputUrl, setUrl] = useState('');
    const [urlCount, setCount] = useState(0);
    const [urlList, setUrlList] = useState([]);
    const urlChange = (e) => {
        setUrl(e.target.value);
        setUrlList(e.target.value.split("\n"));
        setCount(urlList.length);
    };

    const [inputMsg, setMsg] = useState('');
    const msgChange = (e) => {
        setMsg(e.target.value);
    };

    const [inputDelay, setDelay] = useState(1000);
    const delayChange = (e) => {
        setDelay(parseInt(e.target.value));
    };

    const worker = useMemo(() => 
        new Worker(
            new URL('./worker/WebhookWorker.js', import.meta.url)
        ),
    []);
    
    const [send, setSend] = useState(true);
    const toggleSend = (event) => {
        event.preventDefault();
        console.log(send);
        setSend(!send);

        worker.postMessage({
            inputDelay: inputDelay,
            inputMsg: inputMsg,
            inputUrl: inputUrl,
            send: send
        })
    };

    const [state, setState] = useState('Send');
    const toggleState = (event) => {
        if (state === 'Send') {
            setState('Stop');
        } else if (state === 'Stop') {
            setState('Send');
        }
    };

    const download = () => {
        const blob = new Blob([inputUrl], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        document.location.href = url;
    };

    return (
        <div className="Webhook">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}

                <div className="main-container">
                    <form onSubmit={toggleSend} action="" method="get" className="main-form">
                        <p id="Count">Count: {urlCount}</p>
                        <textarea id="Url" type="url" name="webhookUrl" rows="5" cols="1000" placeholder="Webhook URL" onChange={urlChange}
                        required/>
                        <button id="Download-button" onClick={download}>Download</button>
                        <textarea id="Msg" name="message" rows="5" cols="30" placeholder="Message" onChange={msgChange}
                        required />
                        <input id="Delay" type="number" name="delay" placeholder="Delay (ms)" onChange={delayChange}
                        required />
                        <motion.div
                            whileTap={{
                                scale: [0.5, 1.0]
                            }}
                            initial={{scale: 1.0}}
                        >
                            <input id="Send-button" type="submit" value={state} onClick={toggleState}/>
                        </motion.div>
                    </form>

                    <a href="webhook/htu">How to use</a>
                </div>
            </motion.div>
        </div>
    );
}

export default Webhook;