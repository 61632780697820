import '../../css/tools/Base64.css'
import { getHeader } from '../../../../utils/app/AppUtils';
import { useState, useMemo, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import arrow from '../../../../../assets/image/arrow.svg';

function Base64() {
    useEffect(() => {
        document.title = "Base64 Tool"
    }, []);

    const [inputText, setText] = useState('');
    const textChange = (e) => {
        setText(e.target.value);
    };

    const [selectType, setType] = useState('Decode');
    const typeChange = (e) => {
        setType(e.target.value);
    };

    const [isOpenSelect, setIsOpenSelect] = useState(false);
    const toggleIsOpen = () => {
        setIsOpenSelect(!isOpenSelect);
    };

    const worker = useMemo(() => 
        new Worker(
            new URL('./worker/Base64Worker.js', import.meta.url)
        ),
    []);
    
    const convert = (e) => {
        e.preventDefault();

        worker.postMessage({
            inputText: inputText,
            selectType: selectType
        })
    };

    const dataRef = useRef(null);

    const copyData = () => {
        const textarea = dataRef.current;

        textarea.select();
        document.execCommand('copy');
    }

    worker.onmessage = function(e) {
        const textarea = dataRef.current;
        textarea.value = e.data.convertedData;
    };

    return (
        <div className="Base64">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}

                <div className="main-container">
                    <form onSubmit={convert} action="" method="get" className="main-form">
                        <textarea id="Text" name="Data" rows="5" cols="30" placeholder="Data" onChange={textChange}
                        required />
                        <motion.div
                            initial={{ height: isOpenSelect ? 'auto' : 50, scale: 1.0 }}
                            animate={isOpenSelect ? { height: 'auto' } : { height: 50 }}
                            transition={{ duration: 0.3 }}
                            whileTap={{
                                scale: [0.5, 1.0]
                            }}
                        >
                            <select name="type" id="Type-select" onClick={toggleIsOpen} onSelect={toggleIsOpen} onChange={typeChange}>
                                <option value="Decode">Decode</option>
                                <option value="Encode">Encode</option>
                            </select>
                        </motion.div>

                        <motion.div
                            whileTap={{
                                scale: [0.5, 1.0]
                            }}
                            initial={{scale: 1.0}}
                        >
                            <input className="Convert-button" type="submit" value="Convert"/>
                        </motion.div>
                    </form>

                    <motion.div
                        animate={{rotate: 90}}
                        transition={{duration: 1}}
                    >
                        <img src={arrow} alt="arrow" className='arrow'/>
                    </motion.div>

                    <textarea ref={dataRef} id="Text" name="Converted-data" rows="5" cols="30" readOnly/>
                    <motion.div
                        whileTap={{
                            scale: [0.5, 1.0]
                        }}
                        initial={{scale: 1.0}}
                    >
                        <button className="Convert-button" onClick={copyData}>Copy</button>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
}

export default Base64;