import './css/AppsHome.css';
import { getHeader } from '../../utils/app/AppUtils';
import { Glow, GlowCapture } from '@codaworks/react-glow';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

function AppsHome() {
    useEffect(() => {
        document.title = "Apps"
    }, []);

    const [isOpenDiscord, setIsOpenDiscord] = useState(false);
    const toggleAccordionDiscord = () => {
        setIsOpenDiscord(!isOpenDiscord);
    };

    const [isOpenTools, setIsOpenTools] = useState(false);
    const toggleAccordionTools = () => {
        setIsOpenTools(!isOpenTools);
    };

    return (
        <div className="AppsHome">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}
                
                <div className="Discord">
                    <GlowCapture>
                        <Glow color="blue">
                            <motion.div
                                whileTap={{
                                    scale: [0.5, 1.0]
                                }}
                                initial={{scale: 1.0}}
                            >
                                <button onClick={toggleAccordionDiscord}>Discord</button>
                            </motion.div>
                            <motion.div
                                initial={isOpenDiscord ? { height: 'auto', scale: 1.0 } : { height: 0, scale: 0.5 }}
                                animate={isOpenDiscord ? { height: 'auto', scale: 1.0 } : { height: 0, scale: 0.5 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: 'hidden' }}
                            >
                                <div>
                                    <a href="apps/discord/webhook">
                                        <p>Webhook Spammer</p>
                                    </a>
                                </div>
                            </motion.div>
                        </Glow>
                    </GlowCapture>
                </div>

                <div className="Tools">
                    <GlowCapture>
                        <Glow color="rgb(57, 57, 57)">
                            <motion.div
                                whileTap={{
                                    scale: [0.5, 1.0]
                                }}
                                initial={{scale: 1.0}}
                            >
                                <button onClick={toggleAccordionTools}>Tools</button>
                            </motion.div>
                            <motion.div
                                initial={isOpenTools ? { height: 'auto', scale: 1.0 } : { height: 0, scale: 0.5 }}
                                animate={isOpenTools ? { height: 'auto', scale: 1.0 } : { height: 0, scale: 0.5 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: 'hidden' }}
                            >
                                <div>
                                    <a href="apps/tools/base64">
                                        <p>Base64 Tool</p>
                                    </a>
                                </div>
                            </motion.div>
                        </Glow>
                    </GlowCapture>
                </div>
            </motion.div>
        </div>
    );
}

export default AppsHome;