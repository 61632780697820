import { useEffect } from 'react';

function Index() {
    useEffect(() => {
        window.location.href = "/home";
    }, []);
    
    return null;
}

export default Index;