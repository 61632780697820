import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Temu from './page/Temu';
import Index from './page/Index';
import Link from './page/Link';
import Register from './page/Register';
import AppsHome from './page/apps/AppsHome';
import NotFound from './page/NotFound';
import DiscordWebhook from './page/apps/page/discord/Webhook';
import DiscordWebhookHTU from './page/apps/page/discord/HowToUse';
import Base64 from './page/apps/page/tools/Base64';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Index/>} />
                    <Route path="*" element={<NotFound/>} />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/temu" element={<Temu/>} />
                    <Route path="/links" element={<Link/>} />
                    <Route path="/register" element={<Register/>} />
                    
                    <Route path="/apps" element={<AppsHome/>} />
                    <Route path="/apps/discord/webhook" element={<DiscordWebhook/>} />
                    <Route path="/apps/discord/webhook/htu" element={<DiscordWebhookHTU/>} />
                    <Route path="/apps/tools/base64" element={<Base64/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;