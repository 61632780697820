import refresh from '../../assets/image/refresh.svg'
import '../../css/Home.css';
import { motion } from 'framer-motion';
import { Glow, GlowCapture } from '@codaworks/react-glow';
import { useEffect, useState } from 'react';
import { getHeader } from '../utils/app/AppUtils';

function getMenuHover(url, title) {
    return (
        <motion.div
            whileHover={{
                scale: [0.9, 1.0]
            }}
            initial={{ scale: 1.0 }}
        >
            <a href={url}>
                <p className="menu-text glowable-element">{title}</p>
            </a>
        </motion.div>
    )
}

function Home() {
    const [lastCommit, setLastCommit] = useState('');

    useEffect(() => {
        document.title = "Home"
        const fetchData = async () => {
            try {
                const response = await fetch('/lastcommit.txt');
                const data = await response.text();
                setLastCommit(data)
            } catch (error) {
                console.error('Error fetching text:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="App">
            <motion.div
                animate={{ opacity: [0.0, 1.0] }}
                transition={{ duration: 1}}
            >
                {getHeader()}

                <GlowCapture>
                    <Glow color='#fffc4c'>
                        <motion.div
                            animate={{ scale: [0.98, 1.0] }}
                            transition={{ repeat: Infinity, repeatDelay: 5 }}
                            initial={{ scale: 1.0 }}
                            className='lastcommit'
                        >
                            <img src={refresh} alt="refresh" className='emoji'/>
                            <p className='glowable-element'>Last commit: {lastCommit}</p>
                        </motion.div>
                    </Glow>

                    <Glow color='#3790e2'>
                        <div className="menu-position-relative">
                            <div className="menu-container glowable-element">
                                {getMenuHover("/links", "Links")}
                                {getMenuHover("/apps", "Apps")}
                            </div>
                        </div>
                    </Glow>
                </GlowCapture>
            </motion.div>
        </div>
  );
}

export default Home;